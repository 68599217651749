<template>
  <ul class="contact-details">
    <li><router-link :to="{name: 'contact'}">Register your interest today</li>
    <li><a href="tel:0800683797">Phone 0800 683 797</a></li>
    <li><a href="https://goo.gl/maps/gi3b93WCb2ENx91F8" target="_blank">Spring Grove Lifestyle Village<br/>75 Belfast Road, Christchurch, 8051</a></li>
    <li v-if="showmgh"><a href="tel:+64 3 354 0166">Phone 03 354 0166</a></li>
    <li v-if="showmgh">Mike Greer Homes Canterbury<br/>7 Deans Ave, Tower 2, Christchurch 8011</li>
  </ul>
</template>


<script>
  export default {
    props: {
      showmgh: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>