<template>
  <div class="back-link container no-gap">
    <router-link :to="{name : to}">
      <slot></slot>
    </router-link>
  </div>
</template>

<script>

  export default {
    props: {
      to: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        
      }
    }
  }
</script>