<template>
	<div class="break-image is-cover">
		<slot></slot>
	</div>
</template>


<script>

  export default {
    data() {
      return {
        
      }
    }
  }
</script>