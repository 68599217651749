<template>
  <main :class="{'is-green' : isContact}">

    <template v-if="isError">
      <router-view :key="this.route"></router-view>
    </template>

    <template v-else>
      <app-menu></app-menu>
      <div id="content">
        <router-view :key="this.route"></router-view>
      </div>
      <app-footer v-if="!isContact"></app-footer>
    </template>
    
  </main>
</template>

<script>
  import AppMenu from '~/components/AppMenu.vue'
  import AppFooter from '~/components/AppFooter.vue'

  export default {
    components: {AppMenu, AppFooter},
    data() {
      return {
        
      }
    },
    computed: {
      route() {
        return this.$route.name
      },
      isError(){
        return this.route === 'error'
      },
      isContact(){
        return this.route === 'contact'
      },
    }
  }
</script>
