<template>
  <footer> 
    <div class="mgh-strap">
      <div class="container grid">
        <a href="tel:0800645347337">
          Call us today on <span>0800 mikegreer</span>
        </a>
        <a href="https://mikegreerhomes.co.nz" target="_blank" class="mgh-logo">
          <img :src="$getImageSrc('mgh-logo')">
        </a>
      </div>
    </div>  
    <div class="footer-wrapper">
      <div class="container grid gap-80">
        <contact-details></contact-details>
        <img :src="$getImageSrc('logo-reverse')">
      </div>
    </div>
  </footer>
</template>


<script>
  import ContactDetails from "~/components/ContactDetails.vue";

  export default {
    components: { ContactDetails },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>