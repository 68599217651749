<template>
	<div class="article-grid">
		<template v-for="(item,index) in data">
			<router-link :to="{name : 'article', params: {slug : item.slug}}" class="article">
				<div v-if="item.image.length" class="img-wrapper is-cover sixteen-nine">
					<craft-image-helper v-if="item.image.length" :src="item.image[0]"></craft-image-helper>
				</div>
			  <ul>
			    <li>{{item.postDate}}</li>
			    <li>{{item.title}}</li>
			    <li>{{item.teaser}}</li>
			    <li>Read more ></li>
			  </ul>
			</router-link>
		</template>
	</div>
</template>


<script>

  export default {
  	props: {
  		data: {
  			type: Array, 
  			required: true
  		}
  	},
    data() {
      return {
        
      }
    },
    computed: {
    }
  }
</script>