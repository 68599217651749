
<!--
  NOTES:
  - Component is not included globally, include at component level, or for a craft project include in `app.js`
  - Ensure `fullURL` is set within $store
  - Craft supplied sizes must match those in `defaultSizes`
  - Use `s` prefix for Craft image transforms – s400,s800,s1200 etc.
-->


<template >
  <img v-if="src" ref="img" src='/assets/img/pixel.png' :style="{objectPosition : computedPosition}" :alt="alt">
</template>


<script>
  export default {
    props: {
      src: {
        type: Object,
        required: true
      },
      debug: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        url: this.$store.state.fullURL,
        defaultSizes: [400,800,1200,1600,2000,2400,2800,3200],
        dpr: (Math.round(window.devicePixelRatio * 4) / 4).toFixed(2),
      }
    },
    computed: {
      computedPosition(){
        return `${this.src.focalPoint[0] *100}% ${this.src.focalPoint[1] *100}%`
      },
      alt(){
        return this.src.title
      },
      breakpoint() {
        return this.$store.state.breakpoint
      },
    },
    methods: {
      xsResize() {
        if(this.breakpoint === 'xs'){
          this.getSrc()
        }
      },
      getSrc() {

        // dummy pixel ref
        let image = this.$refs.img
        let width = Math.floor(image.offsetWidth)
        let height = Math.floor(image.offsetHeight)
        let orientation = (width > height) ? 'landscape' : 'portrait'
        let ratio = (width/height)

        // source image
        let curImage = this.src
        let imgWidth = curImage.width
        let imgHeight = curImage.height
        let imgRatio = (imgWidth/imgHeight)
        
        // calcs
        let overflow = (ratio > imgRatio) ? overflow = 'y' : overflow = 'x'
        let proposedWidth = Math.round(height * (imgWidth/imgHeight))
        let availableSizes = []

        // inject default sizes less than maxSize into availableSizes
        this.defaultSizes.forEach(function (value, index) {
          if(value <= imgWidth) {
            availableSizes.push(value)
          }
        });

        // debug
        if(this.debug) {
          console.log('------')
          console.log(`${curImage.title}`)
          console.log(curImage)
          console.log(`Max size available : ${this.defaultSizes[availableSizes.length -1]}px`)
          console.log(`Provided size : ${imgHeight}px x ${imgWidth}px`)
          console.log(`Overflow : ${overflow}`)
        }

        if(overflow = 'y') {
          if(width > proposedWidth) {
            proposedWidth = width
          } else {
            proposedWidth = proposedWidth
          }
        } else {
          proposedWidth = width
        }       

        if(this.debug) {
          console.log(`Width with overflow: ${proposedWidth}px`)
        }

        // update proposedWidth
        proposedWidth = proposedWidth * this.dpr

        if(this.debug) {
          console.log(`Width adjusted for DPI: ${proposedWidth}px`)
        }

        // get the next largest size from availableSizes
        let injectedSize
        let foundMatch = false

        availableSizes.forEach(function (value, index) {
          if(!foundMatch) {
            injectedSize = value
            if(value > proposedWidth && !foundMatch) {
              foundMatch = true
              return
            }
          }
        });

        // push result to template
        if(!foundMatch) {
          if(this.debug) {
            console.log(`Image less than 400px detected, using default URL`)
          }
          this.$refs.img.src = this.url + this.src.url
        } else {
          this.$refs.img.src = this.url + (curImage['s'+injectedSize])
          if(this.debug) {
            console.log(`Injected size: ${injectedSize}px`)
          }
        }


        if(this.debug) {
          console.log(`Injected size: ${injectedSize}px`)
          console.log('URL : ' + this.$refs.img.src)
        }
      },
    },
    mounted() {
      this.getSrc()
    },
    watch: {
      breakpoint() {
        this.getSrc()
      },
      src() {
        this.getSrc()
      }
    },
    // XS may need native resize watch, hidden for now, see how it plays out
    // created() {
    //   window.addEventListener('resize',this.xsResize);
    // },
    // destroyed() {
    //   window.removeEventListener('resize',this.xsResize);
    // },
  }
</script>

