<template>
  <div class="module container grid sm-col-2 gap-30"> <!-- data-aos="fade-up" -->
    <h2 :data-aos="fadeUp">
    	<slot name="title"></slot>
    </h2>
    <div class="module-content">
    	<slot name="content"></slot>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      fadeTitle: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
      }
    },
    computed: {
      hasTitle() {
        return !!this.$slots.title
      },
      fadeUp() {
        return (this.fadeTitle) ? 'fade-up' : '';
      }
    }
  }
</script>