<template>
  <div id="homes">

    <introduction>
      Light. Spacious. Modern.
    </introduction>

    <module>
      <template v-slot:title>
        Contemporary homes to suit all lifestyles
      </template>
      <template v-slot:content>
        <p>The combination of landscaped gardens and superbly finished homes make Spring Grove Lifestyle Village a simply beautiful place to live.</p>
        <p>Homes in the village range from one bedroom terraced apartments right through to three bedroom standalone homes, offering a wide array of options to suit every lifestyle. Designed and built by Mike Greer Homes, each home is carefully crafted, superbly finished and comes with industry-leading quality guarantees.</p>
      </template>
    </module>

    <module class="single">
      <template v-slot:title>
        Choose your favourite plan
      </template>
      <template v-slot:content>
        <homes-grid></homes-grid>
      </template>
    </module>

    <module>
      <template v-slot:title>
        Quality assured
      </template>
      <template v-slot:content>
        <p>Each home at Spring Grove is superbly finished by Mike Greer Homes. With over 25 years of experience in home building, Mike Greer Homes has earned a leading reputation for excellence in both design and craftsmanship.</p>
        <p>As with all Mike Greer Homes, your home at Spring Grove Lifestyle Village has been designed by an award-winning team, comes with a Master Build 10 Year Guarantee, and meets the latest standards in comfort and energy efficiency.</p>
        <p>
          <a target="_blank" href="https://mikegreerhomes.co.nz" class="inline-link">Learn more</a>
        </p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        Adding your personal touch
      </template>
      <template v-slot:content>
        <p>While each home will be of a consistent quality standard and aesthetic, there are opportunities to personalise. Depending on where you enter into the buying process you may be able to tailor aspects to your liking, such as choosing your internal colour scheme and other finishing details.</p>
        <p>Keen gardeners are free to put their green thumbs to work too, planting favourite flowers and even some tidy veggie boxes around the home. And of course, if you want to do all your own gardening, you’re certainly welcome to! But rest assured that we will take care of all exterior work if you prefer to simply sit back and enjoy.</p>
      </template>
    </module>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import HomesGrid from "~/components/HomesGrid.vue";

  export default {
    components: { Introduction, Module, HomesGrid },
    data() {
      return {
        
      }
    }
  }
</script>