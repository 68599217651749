// import Data from './data.json';

export default {
  state: {
    loaded: false,
    breakpoint: '',
    fullURL: process.env.BASE_URL,
    articlesShown: 0,
    title: '',
    homes: [
      {
        id: 'totara',
        title: 'Totara',
        beds: 3,
        baths: 2,
        garages: 2,
        carparks: 0,
        size: '155m²',
        blurb: 'At 155m², the Totara is a generously sized three bedroom, two bathroom home. Featuring master bedroom with ensuite, internal access double garage, ample storage and open plan living/dining/kitchen, there’s plenty of space for entertaining, hosting visitors and relaxed day-to-day living.',
        floorplans: []
      },
      {
        id: 'rimu',
        title: 'Rimu',
        beds: 2,
        baths: 2,
        garages: 2,
        carparks: 0,
        size: '135m²',
        blurb: 'A spaciously proportioned 135m², two bedroom, two bathroom home. A beautifully lit living/dining/kitchen area with large glazing lets in plenty of natural light, while bedrooms across the wide central hall offer quiet and privacy. Complete with ample storage and internal access double garage, there’s plenty of space for vehicles and assorted ‘toys’!',
        floorplans: []
      },
      {
        id: 'kauri',
        title: 'Kauri',
        beds: 2,
        baths: 1,
        garages: 1,
        carparks: 0,
        size: '90m²',
        blurb: 'A standalone 90m² home with two bedrooms, one bathroom. More compact yet still occupying its own footprint and boasting the convenience of an internal access, single garage. Designed for modern living, the interior features open plan living/dining/kitchen drenched in natural light, large master bedroom and a smaller second bedroom, office or study.',
        floorplans: []
      },
      {
        id: 'manuka',
        title: 'Manuka',
        beds: 1,
        baths: 1,
        garages: 0,
        carparks: 1,
        size: '50m²',
        blurb: 'Our most modest offering, the Manuka is a 50m² one bedroom, one bathroom terraced home. Here you’ll find everything you need and nothing you don’t. One spacious double bedroom with adjoining bathroom, combined living/dining/kitchen, built-in wardrobe,  separate laundry and private outdoor courtyard make for compact yet comfortable living.',
        floorplans: []
      }
    ]
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setCSRFTokenName (state, payload){
      state.csrfTokenName = payload
    },
    setCSRFToken (state, payload){
      state.csrfToken = payload
    },
    setArticlesShown (state, payload){
      state.articlesShown = payload
    },
    setTitle (state, payload){
      state.title = payload
    },
  },
  actions: {
  }
}