<template>
  <div class="introduction container" data-aos="fade-up">
    <h1><slot></slot></h1>
  </div>
</template>


<script>
  export default {
    data(){
      return {
      }
    }
  }
</script>