
import Home from './components/pages/Home'
import Village from './components/pages/Village'
import Freehold from './components/pages/Freehold'
import Homes from './components/pages/Homes'
import HomesDetail from './components/pages/HomesDetail'
import News from './components/pages/News'
import NewsArticle from './components/pages/NewsArticle'
import Contact from './components/pages/Contact'
import ErrorPage from './components/pages/Error'


let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/the-village',
        name: 'village',
        component: Village,
        meta: { title: 'The Village' },
    },
    {
        path: '/the-freehold-difference',
        name: 'freehold',
        component: Freehold,
        meta: { title: 'The Freehold Difference' },
    },
    {
        path: '/the-homes',
        name: 'homes',
        component: Homes,
        meta: { title: 'The Homes' },
    },
    {
        path: '/the-homes/totara',
        name: 'totara',
        component: HomesDetail,
        meta: { title: 'Totara' },
    },
    {
        path: '/the-homes/rimu',
        name: 'rimu',
        component: HomesDetail,
        meta: { title: 'Rimu' },
    },
    {
        path: '/the-homes/kauri',
        name: 'kauri',
        component: HomesDetail,
        meta: { title: 'Kauri' },
    },
    {
        path: '/the-homes/manuka',
        name: 'manuka',
        component: HomesDetail,
        meta: { title: 'Manuka' },
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: { title: 'News & Updates' },
    },
    {
        path: '/news/:slug', 
        name: 'article',
        component: NewsArticle,
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { title: 'Contact' },
    },
    { 
        path: "*", 
        name: 'error',
        component: ErrorPage,
        meta: { title: 'Error!' },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}