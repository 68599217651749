<template>
  <div id="village">

    <introduction>
      Vibrant. Safe. Welcoming.
    </introduction>

    <module>
      <template v-slot:title>
        Your home to own, your place to live
      </template>
      <template v-slot:content>
        <p>Spring Grove Lifestyle Village represents a new offering in retirement living that’s perfectly suited to today’s independently-minded, fun-loving retirees.</p>
        <p>Purposefully designed to promote an engaging lifestyle, Spring Grove Lifestyle Village helps residents forge new connections within a picturesque community while maintaining excellent access to everything beyond.</p>
      </template>
    </module>

    <break-image>
      <image-helper :src="'4'" :position="'40% 20%'" :alt="'Youthful elderly couple exercising in a park'"></image-helper>
    </break-image>

    <module>
      <template v-slot:title>
        The lodge
      </template>
      <template v-slot:content>
        <p>A generously sized communal lodge near the village entrance is home to a variety of resident-driven activities. It can also be hired by residents for special events like milestone celebrations. Complete with kitchen, break-out spaces and outdoor seating, the lodge, once built, will be a place where families are welcome and friendships are forged.</p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        The layout
      </template>
      <template v-slot:content>
        <p>Safety and security have been top-of-mind in the development design with a single road entering into the village and making a one-way loop through. Traffic calming measures ensure safety for people on foot or bicycle. Homes are private and secure, and while the village is ungated for ease of connection, residents are limited to 55+ years of age for peace of mind.</p>
        <a href="/assets/docs/SGLV-Masterplan.pdf" target="_blank" class="btn">View masterplan</a>
      </template>
    </module>

    <module>
      <template v-slot:title>
        The location
      </template>
      <template v-slot:content>
        <p>Spring Grove is set in a semi-rural area on the edge of Belfast, bordered by a historic homestead, meandering stream and leafy green fields. The Christchurch-to-Rangiora bicycle trail runs immediately beside the village; the beach is minutes away; and urban conveniences such as Northwood Supacenta and surrounding amenities.</p>
      </template>
    </module>

    <div class="map no-gap" :class="{'active' : mapVisible}" v-observe-visibility="visibilityChanged">
      <div class="container">
        <inline-svg :src="$getImageSrc('map')"></inline-svg>
      </div>
      <a href="https://goo.gl/maps/gi3b93WCb2ENx91F8" target="_blank">
        <div class="container">Click here to view in GoogleMaps</div>
      </a>
    </div>

    

    <break-image class="no-gap">
      <image-helper :src="'3'" :alt="'Vibrant yellow Kowhai flowers'"></image-helper>
    </break-image>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import BreakImage from "~/components/BreakImage.vue";
  import ImageHelper from "~/components/ImageHelper.vue";

  export default {
    components: { Introduction, Module, BreakImage, ImageHelper },
    data() {
      return {
        mapVisible: false
      }
    },
    methods: {
      visibilityChanged(isVisible) {
        this.mapVisible = isVisible
      }
    }
  }
</script>