<template>
  <div id="freehold">

    <introduction>
      Owned. Valued. Loved.
    </introduction>

    <module>
      <template v-slot:title>
        Freehold ownership means freedom of choice
      </template>
      <template v-slot:content>
        <p>At Spring Grove Lifestyle Village, all 135 homes are owned as freehold unit titles. We’ve chosen a freehold model as opposed to the Occupation Rights Agreement (ORA) model, because we believe all New Zealanders deserve choice in how they live and opportunity for financial reward.</p>
      </template>
    </module>

    <break-image>
      <image-helper :src="'2'" :position="'45% 25%'" :alt="'Elderly couple laughing together.'"></image-helper>
    </break-image>

    <module>
      <template v-slot:title>
        The Spring Grove Lifestyle Village approach
      </template>
      <template v-slot:content>
        <p>When you purchase a home at Spring Grove Lifestyle Village, you buy the title to the home itself. You will also have a share in the communal areas - the village lodge and total land in the village, excluding private patios.</p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        Your freehold asset
      </template>
      <template v-slot:content>
        <p>Because you own the title to your home, you’re free to mortgage, sell or bequeath it following exactly the same process as with any normal New Zealand home. You’re also fully entitled to any capital gains that may have accrued over time.</p> 
        <p>Of course, new owners or their tenants must still follow the 55+ age rules – these apply in perpetuity, so we can assure the nature of the village remains the same even if ownership changes.</p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        The body corporate
      </template>
      <template v-slot:content>
        <p>Spring Grove Lifestyle Village’s common areas fall under the management of an independent Body Corporate that works closely with the Residents’ Committee to meet the needs of the wider village. You will be required to pay a modest weekly fee of about $65-70* to cover your portion of the village’s communal running costs.</p> 
        <p>This single fee covers rates, power, insurance and maintenance for the village lodge and common grounds. It also includes your own house insurance, gardening, lawn mowing, window and house washing, gutter cleaning and other external maintenance services – giving you more time to relax and enjoy life.</p>
        <p>There are no other village fees. Of course, you will need to pay for your own rates, contents insurance, power and telephone charges, just like you would do for any home.</p>
        <p class="v-small">* Subject to change</p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        Comparative results<br/> at 5 years at 5% p.a.
      </template>
      <template v-slot:content>
        <p class="big">“In my view the freehold title ownership model is far more transparent than the licence to occupy model. Freehold ownership gives you more options and means any capital gain sits with you, the property owner.”</p>
        <p class="big strong">Paul Rickerby<br/>Director, Oxford Edge<br>Christchurch Ltd</p>
      </template>
    </module>

    <div class="container graph-grid grid sm-col-2">
      <div class="col sm-span-2-col">
        <inline-svg :src="$getImageSrc('graph')" ></inline-svg>
      </div>
      <div class="col sm-span-2-col">
        <p class="big strong">Purchase Price</p>
        <p class="big">$500,000</p>
      </div>
      <div class="col">
        <p class="big strong">Freehold Villa</p>
        <p class="big">$638,140</p>
        <p class="small">Based upon 5% capital growth p.a.</p>
      </div>
      <div class="col">
        <p class="big strong">Licence to Occupy</p>
        <p class="big">$386,890</p>
        <p class="small">Depreciated value after 5 years</p>
      </div>
    </div>
    

    <break-image class="no-gap">
      <image-helper :src="'1'" :position="'50% 30%'" :alt="'Elderly lady embracing two grandkids lovingly while smiling.'"></image-helper>
    </break-image>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import BreakImage from "~/components/BreakImage.vue";
  import ImageHelper from "~/components/ImageHelper.vue";

  export default {
    components: { Introduction, Module, BreakImage, ImageHelper },
    data() {
      return {
        
      }
    }
  }
</script>