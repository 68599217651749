<template>
  <div id="news" :class="{'loading' : !data.result}">

    <introduction>
      News & Updates
    </introduction>

    <module class="single" v-if="data.result">
      <template v-slot:content>
        <article-grid :data="data.news"></article-grid>
        <div v-if="moreArticles" class="btn load-more" @click="shown = shown + batch">load more</div>
      </template>
    </module>

    <!-- <div class="container">
      <h1>News & Updates</h1>
      <article-grid v-if="data.result" :data="data.news" class="faux-module"></article-grid>
    </div> -->

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import ArticleGrid from "~/components/ArticleGrid.vue";
  import gql from 'graphql-tag'

  export default {
    components: { Introduction, Module, ArticleGrid },
    data(){
        return {
          batch: 12,
          total: 0,
          shown: 12,
          data: {
            result: false,
            news: Array,
          }
        }
      },
      apollo: {
        news: {
          query: gql`query variables($section: [String!], $limit: Int!,
                        $offset: Int!) {
                      news: entries(
                        section: $section, 
                        orderBy: "postDate DESC", 
                        limit: $limit, 
                        offset: $offset
                      )
                      {
                        id
                        title,
                        slug,
                        ...on news_news_Entry{
                          teaser
                          postDate @formatDateTime (format: "d M Y"),
                          slug,
                          image{
                            url,
                            title,
                            width,
                            height,
                            focalPoint,
                            mimeType,
                            s400: url @transform(width: 400),
                            s800: url @transform(width: 800),
                            s1200: url @transform(width: 1200),
                            s1600: url @transform(width: 1600),
                            s2000: url @transform(width: 2000),
                            s2400: url @transform(width: 2400),
                            s2800: url @transform(width: 2800),
                            s3200: url @transform(width: 3200)
                          }
                        }
                      }
                      total: entryCount(section: $section, 
                        orderBy: "date DESC"), 
                    }
                  `,
            variables() {
              var currentDate = new Date().toJSON().slice(0, 10);
              let offset = 0

              if (this.shown == this.batch){
                offset = 0;
              }
              else{
                offset = this.data.news.length
              }

              return {
                section:  'news',
                limit:    this.batch,
                offset:   offset
              }
            },
            result ({ data, loading, networkStatus }) {  
              // console.log('GraphQL results:')
              // console.log(data.news)
              // console.log('GraphQL Total:')
              // console.log(data.total)
              if (data.news !== null){
                this.data.result = true
                if (this.shown == this.batch){
                  // Initial batch
                  this.data.news = data.news
                }
                else{
                  // Stack another
                  this.data.news.push(...data.news)
                }
                this.total = data.total
                this.$store.commit('setArticlesShown', this.data.news.length)
              }
              else{
                this.$router.push({ name: 'serverError' })
              }
            }
        }
      },
      computed:{
        moreArticles(){
          return (this.$store.state.articlesShown < this.total) ? true : false
        }
      }
  }
</script>