<template>
  <div id="news-article" :class="{'loading' : !data.result}" >

    <template v-if="data.result">
      <break-image v-if="data.article.image.length" class="no-gap">
        <craft-image-helper  :src="data.article.image[0]"></craft-image-helper>
      </break-image>

      <back-link :to="'news'">< Back to all news articles</back-link>

      <div class="article-wrapper container">
        <h1 class="news-title">{{data.article.heading}}</h1>
        <div class="wysiwyg faux-module" v-html="data.article.mainContent"></div>
      </div>
      
      <div class="white-bg no-gap">
        <module class="single">
          <template v-slot:title>
            Recent News
          </template>
          <template v-slot:content>
            <article-grid v-if="data.otherArticlesResult" :data="data.otherArticles" class="no-padding"></article-grid>
          </template>
        </module>
      </div>
    </template>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import ArticleGrid from "~/components/ArticleGrid.vue";
  import BreakImage from "~/components/BreakImage.vue";
  import BackLink from "~/components/BackLink.vue";
  import gql from 'graphql-tag'

  export default {
    components: { Introduction, Module, ArticleGrid, BreakImage, BackLink },
    data() {
      return {
        data: {
          article: Object,
          otherArticles: Array,
          result: false,
          otherArticlesResult: false
        },
      }
    },
    apollo: {
      article: {
        query: gql`query variables($slug: [String!]) {
                    article: entry(slug: $slug) {
                      id
                      title,
                      ...on news_news_Entry{
                        heading
                        mainContent
                        postDate @formatDateTime (format: "d M Y"),
                        slug,
                        image{
                          url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                        }
                      }
                    }
                  }
                `,
          variables() {
            return {
              slug: this.$route.params.slug
            }
          },
          result ({ data, loading, networkStatus }) {  
            if (data.article !== null){
              this.$store.commit('setTitle',data.article.title)
              this.data.article = data.article
              this.data.result = true
              console.log('Full graphQL article:')
              console.log(data.article)
            }
            else{
              this.$router.push({ name: 'contentError' })
            }
          }
      },
      otherArticles: {
        query: gql`query variables($section: [String!], $currentSlug: String!) {
                    otherArticles: entries(
                      section: $section, 
                      orderBy: "postDate DESC", 
                      slug: ["NOT", $currentSlug, null],
                      limit: 4
                    )
                    {
                      id
                      title,
                      ...on news_news_Entry{
                        teaser
                        postDate @formatDateTime (format: "d M Y"),
                        slug,
                        image{
                          url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                        }
                      }
                    }
                  }
              `,
        variables() {
           var currentDate = new Date().toJSON().slice(0, 10);
            return {
              section:    'news',
              currentSlug: this.$route.params.slug
            }
        },
        result ({ data, loading, networkStatus }) {  
          if (data.otherArticles !== null){
            this.data.otherArticles = data.otherArticles
            this.data.otherArticlesResult = true
          }
          console.log('Full graphQL otherArticles:')
          console.log(data.otherArticles)
        }
      },
    },
  }
</script>