<template>
  <div id="contact">

    <introduction>
      Get in touch.
    </introduction>

    <module>
      <template v-slot:title>
        Your journey begins today
      </template>
      <template v-slot:content>
        <p>A purpose-built, freehold village for over 55’s, Spring Grove Lifestyle Village is a very special offering on the market.</p> 
        <p>As such, we anticipate a high level of interest in our homes. If you have any questions or wish to secure your spot, we encourage you to get in touch today.</p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        We’re here to make things easy
      </template>
      <template v-slot:content>
        <p>The process of purchasing a home in Spring Grove Lifestyle Village is simple and straightforward, and we’re here to help you at every step.</p> 
        <p>From sending the Sale and Purchase Agreement directly to your solicitor to organising a long-term settlement so that you only need to move once, we’re here to make the process work for you.</p>
        <p>With a friendly team at your service, you’re welcome to ask us questions at any time. And if it suits you, we’d love to join you for a cuppa once you’re comfortably moved in!</p>
      </template>
    </module>

    <module>
      <template v-slot:title>
        Contact us
      </template>
      <template v-slot:content>
        <contact-details :showmgh="true"></contact-details>
        <form-helper :submit="'Submit'" :recaptcha="true">
          <input type="hidden" name="form_id" value="1">
          <input type="text"
                 name="name"
                 placeholder="Name" v-model="name">
          <input type="text"
                 name="phone"
                 placeholder="Phone" v-model="phone">
          <input type="text"
                 name="email"
                 placeholder="Email" v-model="email">
          <div class="select-wrapper">
            <select name="style" id="style" @change="fauxPlaceholder">
              <option value="" selected disabled>Preferred home</option>
              <option disabled>–</option>
              <option v-for="home in homes" :value="home.title">{{home.title}}</option>
              <option value="No preference">No preference</option>
            </select>
          </div>
          <textarea name="message" placeholder="Message" v-model="message"></textarea>
        </form-helper>
      </template>
    </module>

    <break-image class="no-gap">
      <image-helper :src="'6'" :alt="'Close up of bright green foliage'"></image-helper>
    </break-image>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import ContactDetails from "~/components/ContactDetails.vue";
  import BreakImage from "~/components/BreakImage.vue";
  import ImageHelper from "~/components/ImageHelper.vue";
  import FormHelper from '~/components/FormHelper'

  export default {
    components: { Introduction, Module, ContactDetails, BreakImage, ImageHelper, FormHelper },
    data() {
      return {
        name: null,
        email: null,
        phone: null,
        style: null,
        message: null,
        form_id: 1,
      }
    },
    computed: {
      homes() {
        return this.$store.state.homes
      }
    },
    methods: {
      fauxPlaceholder(e) {
        if(e.target.value !== '') {
          e.target.classList.add('changed')
        }
      }
    }
  }
</script>