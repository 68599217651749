<template>
	<div class="homes-grid container">
	  <template v-for="home in homes">
	  	<router-link v-if="route !== home.id" :to="{name : home.id}">
	  	  <div class="img-wrapper is-cover sixteen-nine">
	  	  	<image-helper :src="home.id" :alt="altText(home)"></image-helper>
	  	  </div>
	  	  <h3>{{home.title}}</h3>
	  	  <p>{{specString(home)}}</p>
	  	</router-link>
	  </template>
	  
	</div>
</template>


<script>
	import ImageHelper from "~/components/ImageHelper.vue";

  export default {
  	components: { ImageHelper },
    data(){
      return {
      }
    },
    computed: {
    	route() {
    	  return this.$route.name
    	},
    	homes() {
    		return this.$store.state.homes
    	}
    },
    methods: {
    	altText(home) {
    		return `Render of ${home.title} ${home.beds} bedroom home`
    	},
    	specString(home) {
    		let result = []

    		if(home.beds > 1) {
    			result.push(`${home.beds} Bedrooms`)
    		} else {
    			result.push(`${home.beds} Bedroom`)
    		}

    		if(home.baths > 1) {
    			result.push(`${home.baths} Bathrooms`)
    		} else {
    			result.push(`${home.baths} Bathroom`)
    		}

    		if(home.garages === 2) {
    			result.push(`Double Garage`)
    		} else if (home.garages === 1) {
    			result.push(`Single Garage`)
    		} else {
					if (home.carparks === 2) {
						result.push(`2 Car Parks`)
					} else if (home.carparks === 1) {
						result.push(`Car Park`)
					}
				}

    		return result.join(' / ')
    	}
    }
}
</script>


<!-- 2 bedrooms / 1 bathroom / Double Garage -->