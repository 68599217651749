<template>
  <div id="homes-detail">

    <break-image class="no-gap">
      <image-helper :src="home.id" :alt="altText"></image-helper>
    </break-image>

    <back-link :to="'homes'">< Back to all typologies</back-link>

    <module class="detail" :fade-title="true">
      <template v-slot:title>
        {{home.title}}
      </template>
      <template v-slot:content>
        <p>{{home.blurb}}</p>
        <ul>
          <li>{{bedrooms}}</li>
          <li>{{bathrooms}}</li>
          <li v-if="home.garages">{{garages}}</li>
          <li v-if="home.carparks">{{carparks}}</li>
          <li>{{size}}</li>
        </ul>
        <router-link :to="{name: 'contact'}" class="btn">Register your interest today</router-link>
      </template>
    </module>


    <module class="single floorplan">
<!--       <template v-slot:title>
        Floorplan
      </template> -->
      <template v-slot:content>
        <a :href="floorplanLink" target="_blank">
          <image-helper :src="home.id + '-floorplan'" :alt="floorplanAltText"></image-helper>
        </a>
      </template>
    </module>


    <module>
      <template v-slot:title>
        Standard across all homes
      </template>
      <template v-slot:content>
        <div class="wysiwyg">
          <ul>
            <li>Large windows</li>
            <li>A choice of orientations</li>
            <li>Landscaped gardens</li>
            <li>Private outdoor area</li>
            <li>Choice of internal colourways</li>
            <li>Leading appliances</li>
            <li>Level entry</li>
            <li>Fully tiled wet areas</li>
            <li>Colorsteel roofing and Rockcote cladding</li>
            <li>Solid core front door</li>
            <li>Double glazing</li>
            <li>Full insulation </li>
            <li>Powdercoated aluminium exterior joinery</li>
          </ul>
        </div>
      </template>
    </module>


    <div class="white-bg no-gap">
      <module class="single">
        <template v-slot:title>
          Typologies
        </template>
        <template v-slot:content>
          <homes-grid></homes-grid>
        </template>
      </module>
    </div>

  </div>
</template>

<script>
  import Module from "~/components/Module.vue";
  import HomesGrid from "~/components/HomesGrid.vue";
  import BreakImage from "~/components/BreakImage.vue";
  import ImageHelper from "~/components/ImageHelper.vue";
  import BackLink from "~/components/BackLink.vue";

  export default {
    components: { Module, HomesGrid, BreakImage, ImageHelper, BackLink },
    data() {
      return {
        
      }
    },
    computed: {
      home() {
        let homes = this.$store.state.homes
        let route = this.$route.name
        let result
        Object.keys(homes).forEach(function (key) {
          if(homes[key].id === route) {
            result = homes[key]
          }
        });
        return result
      },
      altText() {
        return `Render of ${this.home.title} ${this.home.beds} bedroom home`
      },
      floorplanAltText() {
        return `Floorplan for ${this.home.title} ${this.home.beds} bedroom home`
      },
      floorplanLink() {
        return this.$getImageSrc(`generated/${this.home.id}-floorplan-3200`).jpg
      },
      bedrooms() {
        let beds = this.home.beds
        return (beds > 1) ? `${beds} Bedrooms` : `${beds} Bedroom`
      },
      bathrooms() {
        let baths = this.home.baths
        return (baths > 1) ? `${baths} Bathrooms` : `${baths} Bathroom`
      },
      carparks() {
        let carparks = this.home.carparks
        return (carparks > 1) ? `${carparks} Car Parks` : `${carparks} Car Park`
      },
      garages() {
        let garages = this.home.garages
        return (garages === 1) ? `Single Garage` : `Double Garage`
      },
      size() {
        return `House ${this.home.size}`
      }
    }
  }
</script>