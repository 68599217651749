<template>
  <div id="error" class="container">
    <div class="grid gap-10">
      <h1 >Oops!</h1>
      <p class="medium">We can't seem to find the page you're looking for.<br/>Here are some useful links instead : </p>
      <app-nav :show-home="true"></app-nav>
    </div>
  </div>
</template>

<script>
  import AppNav from '~/components/AppNav.vue'

  export default {
    components: { AppNav },
    data() {
      return {
      }
    }
  }
</script>