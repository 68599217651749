<template>
  <nav>
    <!-- use @click.native for any actions outside of router -->
    <router-link v-if="showHome":to="{name: 'home'}" @click.native="$emit('close-menu')">Home</router-link>
    <router-link :to="{name: 'village'}" @click.native="$emit('close-menu')">The Village</router-link>
    <router-link :to="{name: 'homes'}" @click.native="$emit('close-menu')">The Homes</router-link>
    <router-link :to="{name: 'freehold'}" @click.native="$emit('close-menu')">The Freehold Difference</router-link>
    <router-link :to="{name: 'news'}" @click.native="$emit('close-menu')">News & Updates</router-link>
    <router-link :to="{name: 'contact'}" @click.native="$emit('close-menu')">Contact</router-link>
  </nav>
</template>


<script>
  export default {
    props: {
      showHome: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
      }
    },
  }
</script>