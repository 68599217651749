
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
// import * as VueGoogleMaps from 'vue2-google-maps'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import AOS from 'aos'
import CraftImageHelper from './components/CraftImageHelper.vue'

	// import 'aos/dist/aos.css';  
	// -- we dont need everything, selected styles in shared.scss
import VueObserveVisibility from 'vue-observe-visibility'
import ImageHelper from './components/ImageHelper.vue'
import App from './components/App.vue'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from './schema.json'
import VueApollo from 'vue-apollo'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VueApollo)

Vue.component('craft-image-helper', CraftImageHelper)
Vue.component('image-helper', ImageHelper)

Vue.use(VueReCaptcha, { siteKey: '6LcECdsaAAAAAFDtnjj5kdMaljQ94_dy0K7kXS2P',loaderOptions: {
	autoHideBadge: true
} })

// Axios + Apollo config
let axiosBaseDomain = process.env.BASE_URL

Axios.defaults.baseURL = axiosBaseDomain;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here    
  uri: `${axiosBaseDomain}/api?token=${token}`
})

// Retrieve schema map
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema
})

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

// Vue.use(VueGoogleMaps, {
// 	load: {
// 		key: 'AIzaSyCBTROq6LuvF_IE1r46-T4AeTSV-0d7my8',
// 		libraries: 'geometry',
// 	},
// })

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

// Vue.use(VueGtag, {
// 	config: { id: "UA-175913832-1" }
// }, router);
router.beforeEach((to, from, next) => {
  store.commit('setTitle', '')
  next();
});
new Vue({
	created() { 
		AOS.init({offset: 0, anchorPlacement: 'top-bottom'}) 
	},
	router,
	store,
	apolloProvider,
	render: createElement => createElement(App)
}).$mount('#app');