
<!-- 
  NOTES :  
  Component is included at a global level. 
  `:src` requires image name only, not extension
  `:key` is required only for modules that are shared accross pages and need to update on route change

  <image-helper :src="'img-name" :position="'center left'" :key="$route.name"></image-helper>
-->



<template>
  <picture>
    <source ref="webp" :sizes="size">
    <source ref="jpg" :sizes="size">
    <img ref="img"
         src='/assets/img/pixel.png'
         :alt="alt"
         :style="{objectPosition : position}">
  </picture>
</template>

<script>
  import Manifesto from "~imageManifesto.json";

  export default {
    props: {
      src: {
        type: String,
        required: true
      },
      alt: String,
      position: String
    },
    data() {
      return {
        size: null,
      }
    },
    computed: {
      curImage() {
        let cleanSrc = this.src.replace(/\.[^/.]+$/, "")
        return Manifesto[cleanSrc]
      },
    },
    methods: {
      picture(format) {
        this.getSize()
        this.$refs.webp.type = 'image/webp'
        this.$refs.webp.srcset = this.getSrcset('webp')
        this.$refs.webp.sizes = this.size
        this.$refs.jpg.srcset = this.getSrcset('jpg')
        this.$refs.jpg.sizes = this.size
        this.$refs.img.src = this.$getImageSrc(this.curImage[0].source)['jpg']
      },
      getSrcset(format) {
        let result = ""
        let obj = this.curImage
        let self = this

        Object.keys(obj).forEach(function (key) {
          let img = self.$getImageSrc(obj[key].source)[format]          
          result += `${img} ${obj[key].width}w, `
        });
        result += result.slice(0, -2);
        return result
      },
      getSize() {
        // dummy pixel ref
        let image = this.$refs.img
        let width = Math.floor(image.offsetWidth)
        let height = Math.floor(image.offsetHeight)
        let orientation = (width > height) ? 'landscape' : 'portrait'
        let ratio = (width/height)

        // source image
        let curImage = this.curImage[0] // we can use the first image to get our ratio
        let imgWidth = curImage.width
        let imgHeight = curImage.height
        let imgRatio = (imgWidth/imgHeight)
        
        // calcs
        let overflow = (ratio > imgRatio) ? overflow = 'y' : overflow = 'x'
        let proposedWidth = Math.round(height * (imgWidth/imgHeight))

        // debug
        // console.log('---')
        // console.log(curImage)
        // console.log('width : ' + width) 
        // console.log('proposed : ' + proposedWidth)
        // console.log('overflow : ' + overflow)

        if(overflow = 'y') {
          if(width > proposedWidth) {
            this.size = width + 'px'
            // console.log('using width')
          } else {
            this.size = proposedWidth + 'px'
            // console.log('using proposed width')
          }
        } else {
          this.size = width + 'px'
          // console.log('using width')
        }        

      },
    },
    mounted() {
      this.picture()
    },
    created() {
      window.addEventListener('resize',this.getSize);
    },
    destroyed() {
      window.removeEventListener('resize',this.getSize);
    },
  }
</script>