<template>
  <div id="home">

    <introduction>
      Freedom. Connection. Growth.
    </introduction>

    <module>
      <template v-slot:title>
        Own your own home in a connected community
      </template>
      <template v-slot:content>
        <p>Spring Grove Lifestyle Village opens the door to new possibilities in retirement living. Breaking free from the traditional model of gated, financially binding villages, at Spring Grove Lifestyle Village you own the title to your home – yet also enjoy the connectedness of living amongst like-minded people.</p>
      </template>
    </module>

    <break-image>
      <image-helper :src="'5'" :position="'38% 20%'" :alt="'Happy elderly couple with youthful faces'"></image-helper>
    </break-image>

    <module>
      <template v-slot:title>
        The best of both worlds
      </template>
      <template v-slot:content>
        <p>Ownership of your home allows your investment room to grow. Should you decide to sell or bequeath your home, you’re free to do so without financial penalties. Plus, as the title holder, any capital gains made are yours to keep.</p>
        <p>At the same time, Spring Grove Lifestyle Village has been expertly designed to create a close-knit community of people over 55. Set amongst landscaped grounds, 135 beautiful homes arc outwards from a central shared lodge where friends and families can gather for a range of activities and events.</p>
        <p>A broad choice of homes for active retirees — in a safe and connected community — located in a simply unbeatable spot.</p> 
        <p>Own your home and love your life here at Spring Grove Lifestyle Village.</p>
        <router-link :to="{name: 'contact'}" class="btn">Register your interest today</router-link>
      </template>
    </module>

    <div class="container icon-grid grid sm-col-3 gap-50">
      <div class="col">
        <inline-svg :src="$getImageSrc('icon-house')"></inline-svg>
        <p>Beautiful new homes designed and built to an impeccable standard</p>
      </div>
      <div class="col">
        <inline-svg :src="$getImageSrc('icon-list')"></inline-svg>
        <p>The freedom and reward of owning the full title to your home</p>
      </div>
      <div class="col">
        <inline-svg :src="$getImageSrc('icon-heart')"></inline-svg>
        <p>A welcoming community, connected to each other and the wider area</p>
      </div>
    </div>

    <break-image class="no-gap">
      <image-helper :src="'7'" :alt="'Render of Rimu 2 bedroom home'"></image-helper>
    </break-image>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";
  import BreakImage from "~/components/BreakImage.vue";
  import ImageHelper from "~/components/ImageHelper.vue";

  export default {
    components: { Introduction, Module, BreakImage, ImageHelper },
    data() {
      return {
        
      }
    }
  }
</script>